import styled from "styled-components";
import { media } from "../../utils/styled/responsive";

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;

  ${media.tablet} {
    grid-auto-flow: row;
  }

  ${media.desktop} {
    grid-auto-flow: column;
  }
`;

export const WineContainer = styled.div`
  width: 100vw;
  height: 80vh;
  display: grid;
  place-items: center;
  max-width: 1600px;
`;
