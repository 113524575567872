import React, { FC } from "react";
import { useInView } from "react-intersection-observer";

import * as Styled from "./AnimatedLine.styles";

export interface AnimatedLineProps {}

const AnimatedLine: FC<AnimatedLineProps> = ({ ...rest }) => {
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  return (
    <Styled.Line
      {...rest}
      ref={ref}
      initial={{ scale: 0 }}
      animate={{ scale: inView ? 1 : 0 }}
      transition={{ duration: 2.4, delay: 0.2 }}
    />
  );
};
export default AnimatedLine;
