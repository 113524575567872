import styled from "styled-components";

import { media } from "../../utils/styled/responsive";

export const CardsSectionWrapper = styled.div`
  position: relative;
  display: grid;

  ${media.desktop} {
    grid-auto-flow: column;
  }
`;
