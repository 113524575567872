import React from "react";

import AnimatedLine from "../../components/AnimatedLine/AnimatedLine";
import Card from "../../components/Card/Card";

import * as S from "./CardsSection.styles";

function CardsSection({ passedData }) {
  return (
    <S.CardsSectionWrapper>
      {passedData.allContentfulCard.edges.map((edge: any) => {
        const { node } = edge;
        return (
          <Card
            number={node.number}
            key={node.id}
            paragraph={node.paragraph.paragraph}
            title={node.title}
            url={node.url}
          />
        );
      })}
      <AnimatedLine />
    </S.CardsSectionWrapper>
  );
}

export default React.memo(CardsSection);
