import React, { FC } from "react";
import { Link } from "gatsby";

import AnimatedLine from "../AnimatedLine/AnimatedLine";
import Button from "../Button/Button";
import LetterReveal from "../Text/LetterReveal/LetterReveal";
import LineReveal from "../Text/LineReveal/LineReveal";

import * as Styled from "./Card.styles";

export interface CardProps {
  number: string;
  id?: string;
  paragraph: string;
  title: string;
  url?: string;
  delay?: number;
}

const Card: FC<CardProps> = ({
  number,
  id,
  paragraph,
  title,
  url,
  delay,
  ...rest
}) => {
  return (
    <Styled.Container {...rest}>
      <Styled.Inner>
        <Styled.Numbers>
          <LetterReveal renderAs="h1" delay={0.6} cName="xl" text={number} />
        </Styled.Numbers>
        <Styled.Content>
          <Link to={url}>
            <LineReveal renderAs="h2" delay={delay} text={title} />
          </Link>
          <LineReveal renderAs="p" delay={0.4} text={paragraph} />
        </Styled.Content>
        <Styled.ButtonWrapper>
          <Button url={url} />
        </Styled.ButtonWrapper>
        <Styled.Line>
          <AnimatedLine />
        </Styled.Line>
      </Styled.Inner>
    </Styled.Container>
  );
};
export default Card;
