import styled from "styled-components";
import { media } from "../../utils/styled/responsive";

export const Canvas = styled.canvas`
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  width: 80vw;
  transition: 0.2s ease;

  ${media.tablet} {
    width: unset;
    height: 75vh;
  }
`;

export const MeasureScroll = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;
