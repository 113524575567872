import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import InView from "react-intersection-observer";
import LetterReveal from "../../components/Text/LetterReveal/LetterReveal";
import LineReveal from "../../components/Text/LineReveal/LineReveal";

import * as S from "./HoneySection.styles";

function HoneySection({ passedData }) {
  const image = getImage(
    passedData.contentfulHoneySection.image.gatsbyImageData
  );
  const { number, paragraph, title } = passedData.contentfulHoneySection;

  return (
    <InView>
      <S.Container>
        <S.NumbersWrapper>
          <LetterReveal text={number} renderAs="h1" cName="xl" />
        </S.NumbersWrapper>
        <S.ImageWrapper>
          <GatsbyImage image={image} alt="zaraz dodam XD" />
        </S.ImageWrapper>

        <S.ContentWrapper>
          <LineReveal renderAs="h2" text={title} />
          <LineReveal delay={0.2} text={paragraph.paragraph} />
        </S.ContentWrapper>
      </S.Container>
    </InView>
  );
}

export default HoneySection;
