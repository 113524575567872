import React, { FC, useEffect, useRef } from "react";

import * as S from "./LandingDesktopAnimation.styles";

export interface LandingDesktopAnimationProps {}

const LandingDesktopAnimation: FC<LandingDesktopAnimationProps> = ({
  ...rest
}) => {
  console.log("ver:1.1");
  const canvasRef = useRef<HTMLCanvasElement>();
  const elementScrollY = useRef<HTMLDivElement>();

  const frameCount = 60;

  const currentFrame = (index: number) =>
    `https://piwnicepoltorak.pl/animations/landing-desktop/${index
      .toString()
      .padStart(4, "0")}.jpg`;

  const preloadImages = () => {
    for (let i = 1; i < frameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i);
    }
  };

  useEffect(() => {
    preloadImages();
    const context = canvasRef.current.getContext("2d");
    let frameIndex = 1;
    let animationFrameCount = 60;
    const img = new Image();
    img.src = currentFrame(1);
    canvasRef.current.width = 1500;
    canvasRef.current.height = 900;
    img.onload = function () {
      context.drawImage(img, 0, 0);
    };

    const getFrame = () => {
      if (elementScrollY.current !== undefined) {
        const amountScrolled =
          elementScrollY.current.getBoundingClientRect().left * -1;
        const scrollFraction =
          (Math.abs(amountScrolled) / window.innerHeight) * 1.5;
        const index = Math.min(
          animationFrameCount - 1,
          Math.ceil(scrollFraction * animationFrameCount)
        );

        frameIndex = index > 0 ? index : 1;

        requestAnimationFrame(() => updateImage(Math.ceil(frameIndex)));
      }
    };

    const updateImage = (index) => {
      img.src = currentFrame(index);
      context.drawImage(img, 0, 0);
    };

    window.addEventListener("wheel", getFrame, { passive: true });
    return () => window.removeEventListener("wheel", getFrame);
  }, []);

  return (
    <>
      <S.Canvas ref={canvasRef} {...rest} />
      <S.MeasureScroll ref={elementScrollY} />
    </>
  );
};
export default React.memo(LandingDesktopAnimation);
