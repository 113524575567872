import styled from "styled-components";

import { media } from "../../utils/styled/responsive";

export const Container = styled.div`
  position: relative;
  width: 100vw;

  padding: 0 24px;

  ${media.tablet} {
    padding: 0 64px;
  }

  ${media.desktop} {
    padding: 0 40px;
    width: 440px;
    height: 100vh;
  }
`;

export const Inner = styled.div`
  display: grid;
  align-items: center;
  max-width: 100%;
`;

export const Numbers = styled.div`
  position: absolute;
  display: inline-block;
  right: 24px;
  top: 24px;

  ${media.tablet} {
    right: 64px;
    top: 40px;
  }

  ${media.desktop} {
    right: 40px;
    top: 160px;
  }
`;

export const Content = styled.div`
  display: grid;
  row-gap: 24px;
  padding: 108px 0;
  height: min-content;
  grid-template-rows: min-content min-content;

  ${media.tablet} {
    row-gap: 32px;
    max-width: 50vw;
    width: 50vw;
    align-self: center;
  }

  ${media.desktop} {
    position: absolute;
    left: 0;
    top: 50vh;
    padding: 0 56px;
    row-gap: 64px;
    max-width: 100%;
  }
`;
export const ButtonWrapper = styled.div`
  position: absolute;
  right: 24px;
  bottom: 24px;

  ${media.tablet} {
    right: 64px;
    bottom: 40px;
  }

  ${media.desktop} {
    bottom: 40px;
    right: 40px;
  }
`;

export const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  right: 0;
  bottom: 0;

  ${media.desktop} {
    right: 0;
    bottom: 0;
  }
`;
