import styled from "styled-components";
import { media } from "../../utils/styled/responsive";

export const Container = styled.div`
  display: grid;
  padding: 16px;

  ${media.tablet} {
    padding: 64px;
  }

  ${media.desktop} {
    padding: 64px;
    height: 100vh;
    width: 100vw;
    max-width: 2400px;
    position: relative;
    grid-template-columns: 1fr 1fr;
  }
`;
export const NumbersWrapper = styled.div`
  justify-self: right;
  display: inline-block;

  ${media.desktop} {
    position: absolute;
    top: 160px;
    right: 64px;
  }
`;
export const ImageWrapper = styled.div`
  z-index: -1;

  ${media.tablet} {
    transform: scaleX(-1) translateY(40px);
    width: 60vw;
    justify-self: right;
  }

  ${media.desktop} {
    transform: scaleX(1) translateY(64px);
    width: 100%;
    align-self: end;
    justify-self: unset;
  }
`;
export const ContentWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  row-gap: 24px;

  ${media.tablet} {
    width: 40vw;
    row-gap: 32px;
    margin-top: -80px;
  }

  ${media.desktop} {
    margin-top: unset;
    align-self: center;
    width: 30vw;
    transform: translateY(160px);
    max-width: 560px;
  }
`;
export const ButtonWrapper = styled.div`
  justify-self: right;

  ${media.desktop} {
    position: absolute;
    bottom: 64px;
    right: 64px;
  }
`;
