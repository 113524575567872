import React, { FC } from "react";

import { useLanguage } from "../../hooks/useLanguage";
import LineReveal from "../../components/Text/LineReveal/LineReveal";
import ProductList from "../../components/ProductList/ProductList";

import * as Styled from "./ProductsSection.styles";

export interface ProductsSectionProps {
  passedData: any;
}

const ProductsSection: FC<ProductsSectionProps> = ({ passedData }) => {
  const [language] = useLanguage();
  return (
    <Styled.Container>
      <Styled.WineContainer>
        <LineReveal
          renderAs="h1"
          cName="xxl"
          text={language === "pl" ? "Wina" : "Wines"}
        />
      </Styled.WineContainer>

      <ProductList passedData={passedData} />
    </Styled.Container>
  );
};
export default ProductsSection;
