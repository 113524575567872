import React, { useEffect, useRef } from "react";

import * as S from "./LandingMobileAnimation.styles";

function LandingMobileAnimation() {
  const canvasRef = useRef<HTMLCanvasElement>();
  const elementScrollY = useRef<HTMLDivElement>();

  const frameCount = 60;

  const currentFrame = (index) =>
    `https://piwnicepoltorak.pl/animations/landing-mobile/${index
      .toString()
      .padStart(4, "0")}.jpg`;

  const preloadImages = () => {
    for (let i = 1; i < frameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i);
    }
  };

  useEffect(() => {
    preloadImages();
    const context = canvasRef.current.getContext("2d");
    let frameIndex = 1;
    const img = new Image();
    img.src = currentFrame(1);
    canvasRef.current.width = 600;
    canvasRef.current.height = 900;
    img.onload = function () {
      context.drawImage(img, 0, 0);
    };

    const getFrame = () => {
      if (elementScrollY.current === null) {
        return;
      }
      const amountScrolled =
        elementScrollY.current.getBoundingClientRect().y * -1;
      const scrollFraction =
        (Math.abs(amountScrolled) / window.innerHeight) * 2;

      const index = Math.min(
        frameCount - 1,
        Math.ceil(scrollFraction * frameCount)
      );

      frameIndex = index > 0 ? index : 1;

      requestAnimationFrame(() => updateImage(Math.ceil(frameIndex)));
    };

    const updateImage = (index: number) => {
      img.src = currentFrame(index);
      context.drawImage(img, 0, 0);
    };

    window.addEventListener("scroll", getFrame, { passive: true });
    return () => window.removeEventListener("scroll", getFrame);
  }, []);

  return (
    <>
      <S.Canvas ref={canvasRef} />
      <S.MeasureScroll ref={elementScrollY} />
    </>
  );
}

export default React.memo(LandingMobileAnimation);
