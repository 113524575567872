import styled from "styled-components";

import { m } from "framer-motion";
import { media } from "../../utils/styled/responsive";

export const Container = styled.div`
  position: relative;
  display: grid;
  width: 100vw;
  padding: 16px;
  grid-template-rows: 50vh min-content;

  ${media.tablet} {
    padding: 64px;
  }

  ${media.desktop} {
    padding: 64px;
    height: 100vh;

    grid-template-rows: unset;
    grid-template-columns: 1fr 1fr;
  }
`;

export const NumbersWrapper = styled.div`
  justify-self: right;
  display: inline-block;

  height: min-content;

  ${media.desktop} {
    position: absolute;
    top: 160px;
    right: 64px;
  }
`;

export const Inner = styled.div`
  display: grid;
`;

export const ContentWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  row-gap: 24px;

  ${media.tablet} {
    width: 50vw;
    row-gap: 32px;
    margin-top: -80px;
  }

  ${media.desktop} {
    margin-top: unset;
    align-self: center;
    width: 30vw;
    transform: translateY(160px);
    max-width: 560px;
  }
`;

export const LineWrapper = styled.div`
  position: absolute;
  display: grid;
  place-items: end;
  width: 100%;
  height: 100%;
  pointer-events: none;
  right: 0;
  bottom: 0;

  ${media.desktop} {
    right: 0;
    bottom: 0;
  }
`;

export const AnimatedLine = styled(m.div)`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.black[1]};
  transform-origin: left;
  will-change: transform;

  ${media.desktop} {
    transform-origin: bottom;
    height: 100%;
    width: 1px;
  }
`;
