import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ImagesContainer } from "./ImageContainer";
import AnimatedLine from "../../components/AnimatedLine/AnimatedLine";
import LetterReveal from "../../components/Text/LetterReveal/LetterReveal";
import LineReveal from "../../components/Text/LineReveal/LineReveal";

import * as S from "./BlueberriesSection.styles";

function BlueBerrySection({ passedData }) {
  const { number, paragraph, title, images } =
    passedData.contentfulBlueberriesSection;

  // Generates component for each image
  const allImages = images.map((image, index) => (
    <GatsbyImage
      key={image.title}
      image={getImage(image)}
      alt={image.title}
      className={`img${index}`}
    />
  ));

  return (
    <S.Container>
      <S.Inner>
        <S.NumbersWrapper>
          <LetterReveal text={number} renderAs="h1" cName="xl" />
        </S.NumbersWrapper>
        <ImagesContainer>{allImages}</ImagesContainer>
      </S.Inner>
      <S.Inner>
        <S.ContentWrapper>
          <LineReveal renderAs="h2" text={title} />
          <LineReveal delay={0.2} text={paragraph.paragraph} />
        </S.ContentWrapper>
      </S.Inner>
      <S.LineWrapper>
        <AnimatedLine />
      </S.LineWrapper>
    </S.Container>
  );
}

export default BlueBerrySection;
