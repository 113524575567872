import styled from "styled-components";

export const Canvas = styled.canvas`
  position: absolute;
  left: auto;
  right: auto;
  top: auto;
  bottom: 0;
  height: 100vh;
  max-height: 1170px;
  max-width: 1950px;
`;

export const MeasureScroll = styled.div`
  position: fixed;
  left: 0;
  top: 0;
`;
