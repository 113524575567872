import styled from "styled-components";
import { m } from "framer-motion";

import { media } from "../../utils/styled/responsive";

export const Line = styled(m.div)`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.black[1]};
  transform-origin: left;
  will-change: transform;

  ${media.desktop} {
    transform-origin: bottom;
    height: 100%;
    width: 1px;
  }
`;
