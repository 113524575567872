import styled from "styled-components";

import { media } from "../../utils/styled/responsive";

export const ImagesContainer = styled.div`
  & .img0,
  .img1,
  .img2,
  .img3,
  .img4,
  .img5,
  .img6 {
    position: absolute;
    width: 0;
  }
  & .img0 {
    width: 14vw;
    right: 5vh;
    top: 20vh;
    transform: scaleX(-1);

    ${media.tablet} {
      width: 10vw;
      top: 28vh;
      right: 2vw;
    }

    ${media.desktop} {
      width: 4vw;
      left: 25vw;
      right: unset;
      top: 20vh;
    }
  }
  & .img1 {
    width: 10vw;
    left: 50vw;
    top: 5vh;

    ${media.tablet} {
      width: 8vw;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      width: 5vw;
      left: 18vw;
      right: unset;
      top: 55vh;
    }
  }
  & .img2 {
    width: 22vw;
    top: 25vh;
    left: -4vw;

    ${media.desktop} {
      width: 14vw;
      left: unset;
      right: -5vw;
      z-index: 1;
      top: unset;
      bottom: -5vh;
    }
  }
  & .img3 {
    width: 30vw;
    top: 44vh;
    right: -3vw;

    ${media.tablet} {
      top: 65vh;
      width: 25vw;
    }

    ${media.desktop} {
      width: 4vw;
      right: unset;
      top: 40vh;
      left: 40vw;
    }
  }
  & .img4 {
    width: 12vw;
    top: 40vh;
    left: 38vw;

    ${media.desktop} {
      width: 14vw;
      left: 30vw;
      bottom: 10vh;
      top: unset;
    }
  }
  & .img5 {
    width: 6vw;
    top: 25vh;
    left: 58vw;
    opacity: 0.8;

    ${media.desktop} {
      width: 2vw;
      top: 45vh;
      left: 29vw;
    }
  }
  & .img6 {
    width: 40vw;
    bottom: -25vh;
    left: -2vw;

    transform: scaleX(-1);

    ${media.tablet} {
      width: 27vw;
    }

    ${media.desktop} {
      width: 7vw;
      bottom: 5vh;
      left: 6vw;
    }
  }
`;
