import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AnimatedLine from "../../components/AnimatedLine/AnimatedLine";
import LetterReveal from "../../components/Text/LetterReveal/LetterReveal";
import LineReveal from "../../components/Text/LineReveal/LineReveal";
import {
  Adress,
  Container,
  Details,
  Email,
  Header,
  ImageContainer,
  Inner,
  LineWrapper,
  NumbersWrapper,
} from "./ContactSection.styles";

function ContactSection({ passedData }) {
  const { image, title, slogan, adress, email } =
    passedData.contentfulContactPage;

  return (
    <Container>
      <NumbersWrapper>
        <LetterReveal text="06" renderAs="h1" cName="xl" />
      </NumbersWrapper>
      <ImageContainer>
        <GatsbyImage image={getImage(image)} alt="" />
      </ImageContainer>
      <Inner>
        <Header>
          <LineReveal renderAs="h1" text={title} />
          <LineReveal text={slogan} />
        </Header>
        <Details>
          <Email>
            <LineReveal style={{ fontWeight: 600, color: "white" }} text="E:" />
            <LineReveal text={email} />
            <LineReveal style={{ fontWeight: 600, color: "white" }} text="T:" />
            <LineReveal text="+48 889 780 094" />
          </Email>
          <Adress text={adress.adress} />
        </Details>
      </Inner>
      <LineWrapper>
        <AnimatedLine />
      </LineWrapper>
    </Container>
  );
}

export default React.memo(ContactSection);
