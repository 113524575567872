import React from "react";

import { graphql } from "gatsby";
import { Mobile } from "../../components/MediaQueries/MediaQueries";

import FadeInOut from "../../components/Animations/FadeInOut/FadeInOut";
import SideScroll from "../../components/Scrolls/SideScroll";
import BlueBerrySection from "../../sections/BlueberriesSection/BlueberriesSection";
import CardSection from "../../sections/CardsSection/CardsSection";
import ContactSection from "../../sections/ContactSection/ContactSection";
import HoneySection from "../../sections/HoneySection/HoneySection";
import Landing from "../../sections/LandingSection/LandingSection";
import ProductsSection from "../../sections/ProductsSection/ProductsSection";
import Shader from "../../components/Shader/Shader";
import ImageList from "../../components/ImageList/ImageList";

export default function HomePage({ data }) {
  const autumnImages = data.contentfulImageBlockAutumn.images;
  const springImages = data.contentfulImageBlockSpring.images;
  return (
    <>
      <FadeInOut>
        <SideScroll>
          <Mobile>
            <Shader />
          </Mobile>

          <Landing />
          <ImageList images={autumnImages} />
          <CardSection passedData={data} />
          <ProductsSection passedData={data} />
          <BlueBerrySection passedData={data} />
          <ImageList images={springImages} />
          <HoneySection passedData={data} />
          <ContactSection passedData={data} />
        </SideScroll>
      </FadeInOut>
    </>
  );
}

export const query = graphql`
  query Home($locale: String) {
    contentfulImageBlockSpring {
      images {
        gatsbyImageData(formats: [AUTO, WEBP], resizingBehavior: SCALE)
        title
      }
    }
    contentfulImageBlockAutumn {
      images {
        gatsbyImageData(formats: [AUTO, WEBP], resizingBehavior: SCALE)
        title
      }
    }
    contentfulContactPage(node_locale: { eq: $locale }) {
      email
      slogan
      image {
        gatsbyImageData(quality: 100)
        title
        node_locale
      }
      title
      url
      adress {
        adress
      }
    }
    allContentfulProduct(
      filter: { onDisplay: { eq: true }, node_locale: { eq: $locale } }
      sort: { fields: priceNumber, order: ASC }
    ) {
      edges {
        node {
          priceNumber
          onDisplay
          node_locale
          id
          shortDescription
          url
          wineName
          year
          image {
            gatsbyImageData(formats: [PNG], placeholder: BLURRED, quality: 100)
            title
            description
          }
        }
      }
    }
    contentfulHoneySection(node_locale: { eq: $locale }) {
      button
      number
      paragraph {
        paragraph
      }
      title
      url
      image {
        gatsbyImageData(formats: [AUTO, WEBP], resizingBehavior: SCALE)
      }
    }
    contentfulBlueberriesSection(node_locale: { eq: $locale }) {
      number
      title
      images {
        id
        title
        gatsbyImageData(formats: [AUTO, WEBP], resizingBehavior: SCALE)
      }
      paragraph {
        paragraph
      }
    }
    allContentfulCard(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: number }
    ) {
      edges {
        node {
          number
          id
          paragraph {
            paragraph
          }
          title
          url
        }
      }
    }
  }
`;
