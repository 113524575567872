import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import { media } from "../../utils/styled/responsive";

export const Container = styled.div`
  display: grid;
  padding: 80px 0;
  width: 100vw;
  place-items: center;
  gap: 120px;

  ${media.tablet} {
    padding: 160px 0;
    gap: 160px;
  }

  ${media.desktop} {
    padding: 0 320px;
    height: 100vh;
    width: max-content;
    grid-auto-flow: column;
    gap: 120px;
  }
`;

export const Image = styled(GatsbyImage)`
  width: 100vw;

  ${media.tablet} {
    width: calc(100vw - 320px);
  }

  ${media.desktop} {
    width: 40vh;
    height: 50vh;

    max-height: 640px;
    max-width: 512px;
  }
`;
