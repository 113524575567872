import { getImage } from "gatsby-plugin-image";
import React from "react";

import ItemReveal from "../ItemReveal/ItemReveal";
import { Container, Image } from "./ImageList.styles";

function ImageList({ images }) {
  return (
    <Container>
      {images.map((image: any) => {
        return (
          <ItemReveal transformOrigin="bottom" key={image.title}>
            <Image image={getImage(image)} alt={image.title} />
          </ItemReveal>
        );
      })}
    </Container>
  );
}

export default ImageList;
